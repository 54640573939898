import Vue from 'vue';
import Router from 'vue-router';
import LoginForm from '@/components/LoginForm.vue';
import ImportForm from '@/components/ImportForm.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginForm,
      meta: { title: 'Inovshop-articles' },
    },
    {
      path: '/importForm',
      name: 'ImportForm',
      component: ImportForm,
      meta: { title: 'Inovshop-articles', requiresAuth: true }, // Indique que l'authentification est requise pour cette route
    },
  ],
});

// Garde de navigation pour vérifier l'authentification
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');

  // Change le titre de l'onglet
  document.title = to.meta.title || 'Inovdrop';

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
