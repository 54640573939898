<template>
  <section
    class="hero is-fullheight is-flex is-justify-content-center is-align-items-center"
  >
    <div class="box has-text-centered">
      <div class="header">
        <img class="logo" src="@/assets/logo_inov/logo.png" alt="Logo" />
        <!-- Bouton d'aide -->
        <button class="button is-info help-button" @click="toggleSidebar">
          <span class="icon is-small"
            ><i class="mdi mdi-help-circle-outline"></i
          ></span>
        </button>
      </div>

      <!-- B-upload pour le drop des fichiers -->
      <b-field>
        <b-upload
          v-model="dropFiles"
          multiple
          drag-drop
          expanded
          @input="onDropFilesSelected"
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"></b-icon>
              </p>
              <p>
                Faites glisser et déposez votre fichier Excel (XLS ou XLSX) ici.
                <br />Le poids du fichier ne doit pas dépasser 8 Mo.
              </p>
            </div>
          </section>
        </b-upload>
      </b-field>

      <!-- Affichage des fichiers dropés -->
      <div class="tags">
        <span
          v-for="(file, index) in dropFiles"
          :key="index"
          class="tag is-primary"
        >
          {{ file.name }}
          <button
            class="delete is-small"
            type="button"
            @click="deleteDropFile(index)"
          ></button>
        </span>
      </div>

      <article class="message is-primary">
        <section class="message-body">
          <B class="mb-1"></B>
          <p class="mb-2">
            Cliquez pour télécharger le modèle de fichier d'import.
          </p>
          <button
            type="button"
            class="button is-primary is-outlined"
            @click="downloadExampleFile"
          >
            <span class="icon is-small"
              ><i class="mdi mdi-file-download-outline"></i
            ></span>
            <span>model-import-article.xlsx</span>
          </button>
        </section>
      </article>

      <article class="message is-primary">
        <section class="message-body">
          <p class="mb-2">
            Cliquez sur boutique pour récuperer les codes boutiques.
          </p>
          <button
            type="button"
            class="button is-primary is-outlined"
            @click="showStoreModal"
          >
            <span class="icon is-small"><i class="mdi mdi-store"></i></span>
            <span class="button-text">Boutique</span>
          </button>
        </section>
      </article>

      <!-- Popup avec tableau des codes boutiques -->
      <b-modal :active.sync="isStoreModalActive" has-modal-card>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Codes Boutiques</p>
            <button
              class="delete"
              aria-label="close"
              @click="closeStoreModal"
            ></button>
          </header>
          <section class="modal-card-body">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th class="has-text-left">Nom</th>
                  <th class="has-text-left">Code</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(store, index) in stores" :key="index">
                  <td class="has-text-left">{{ store.name }}</td>
                  <td class="has-text-left">{{ store.code }}</td>
                </tr>
              </tbody>
            </table>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-success" @click="closeStoreModal">
              Fermer
            </button>
          </footer>
        </div>
      </b-modal>

      <!-- Barre de progression -->
      <div v-if="isUploading" class="progress-container">
        <progress class="progress is-primary" :value="uploadProgress" max="100">
          {{ uploadProgress }}%
        </progress>
        <p>Importation en cours... {{ uploadProgress }}%</p>
      </div>

      <div>
        <button
          class="button is-primary is-fullwidth"
          @click="handleFileUpload"
          :disabled="dropFiles.length === 0 || isUploading"
        >
          <span>Importer</span>
        </button>
      </div>

      <!-- Onglet latéral d'aide -->
      <div class="sidebar" :class="{ 'is-active': isSidebarVisible }">
        <div class="sidebar-content">
          <button class="close-button" @click="toggleSidebar">
            <i class="mdi mdi-close"></i>
          </button>
          <div class="text-content">
            <h2 class="title">Inovprice Tag</h2>
            <h2 class="title">
              Création d'articles par boutique et/ou de mise à jour d'articles
              via import.
            </h2>
            <p>
              <u>Procédure import :</u>
              <br />
              <br />
              1. Télécharger le modèle de fichier d'import en cliquant sur le
              bouton "Model-import-article.xlsx".
              <br />
              2. Supprimer la seconde ligne du fichier qui est un exemple.
              <br />
              3. Ajouter les données article, prix, famille... décrivant chaque
              article en ajoutant le code boutique pour assigner l'article à la
              boutique. Vous pouvez laisser le code boutique vide, l'article
              sera ajouté dans tous les magasins sfr_re.
              <br />
              4. Enregistrer le fichier au format "xls ou xlsx" sur votre poste
              local.
              <br />
              5. Cliquer ou déposer le fichier enregistré sur la zone de dépôt
              blanche.
              <br />
              6. Cliquer sur le bouton "Importer".
              <br />
              7. Vérifier le message informatif pour savoir si vos fichiers ont
              bien été importés. Si un article existe déjà, il sera mis à jour
              automatiquement.
            </p>
            <div class="sidebar-footer">
              <button class="button is-success" @click="toggleSidebar">
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Popup de confirmation -->
      <b-modal :active.sync="isModalActive" has-modal-card>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Détail du traitement de l'import</p>
            <button
              class="delete"
              aria-label="close"
              @click="closeModal"
            ></button>
          </header>
          <section class="modal-card-body">
            <!-- Articles présents dans le fichier -->
            <p>
              <strong>{{ itemsCount - countItemDuplicated }}</strong> article{{
                itemsCount - countItemDuplicated > 1 ? 's' : ''
              }}
              trouvé{{ itemsCount - countItemDuplicated > 1 ? 's' : '' }} pour
              l'import
            </p>
            <!-- Articles sans magasins - dupliqué sur tous les store sfr_re -->
            <p v-if="countItemDuplicated > 0">
              Dont <strong>{{ countItemDuplicated }}</strong> article sans
              magasin renseigné{{ countItemDuplicated > 1 ? 's' : '' }}
            </p>
            <!-- Total des articles importés avec succès -->
            <p class="mx-4">
              <strong>{{ createdCount + countItemDuplicated }}</strong>
              article{{
                createdCount + countItemDuplicated > 1 ? 's' : ''
              }}
              importé{{ createdCount + countItemDuplicated > 1 ? 's' : '' }}
              <!-- Total articles dupliqués -->
              <span v-if="countItemDuplicated > 0"
                >dont

                <strong>{{ countItemDuplicated }}</strong> article{{
                  countItemDuplicated > 1 ? 's' : ''
                }}
                sans magasin importé{{ countItemDuplicated > 1 ? 's' : '' }} sur
                <strong>{{ sfrReStoresId.length }}</strong> magasin{{
                  sfrReStoresId.length > 1 ? 's' : ''
                }}
                ({{ sfrReStoresId[0] }}, {{ sfrReStoresId[1] }})
              </span>
            </p>
            <!-- Erreur trouvées -->
            <p v-if="errorCount > 0" class="is-error">
              <strong>{{ errorCount }}</strong> article{{
                errorCount > 1 ? 's' : ''
              }}
              non importé{{ errorCount > 1 ? 's' : '' }}
            </p>
            <b-collapse :open.sync="isCollapseOpen">
              <button
                slot="trigger"
                class="button error-import mx-auto my-4"
                v-if="errorCount"
              >
                <span>Voir les erreurs </span>
                <b-icon
                  :icon="isCollapseOpen ? 'chevron-down' : 'chevron-right'"
                ></b-icon>
              </button>
              <div class="content">
                <ul>
                  <li
                    class="error-list"
                    v-for="(error, index) in errors"
                    :key="index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </div>
            </b-collapse>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-success" @click="closeModal">
              Fermer
            </button>
          </footer>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      title: '',
      dropFiles: [],
      results: [],
      isModalActive: false,
      isStoreModalActive: false,
      itemsCount: 0,
      importedCount: 0,
      createdCount: 0, // Nombre d'articles créés
      updatedCount: 0, // Nombre d'articles modifiés
      errorCount: 0,
      countItemDuplicated: 0,
      sfrReStoresId: 0,
      errors: [],
      isCollapseOpen: false,
      isUploading: false,
      uploadProgress: 0,
      selectedCategory: null,
      stores: [
        { name: 'STE CLOTILDE', code: 'sfr_re.CEX' },
        { name: 'ST PAUL', code: 'sfr_re.SPA' },
      ],
      isSidebarVisible: false,
    };
  },
  methods: {
    fileValidation(file) {
      const validTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ];
      const validExtensions = ['xlsx', 'xls'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      const isValidType = validTypes.includes(file.type);
      const isValidExtension = validExtensions.includes(fileExtension);
      const isValidSize = file.size <= 8 * 1024 * 1024; // 8 Mo

      return isValidType && isValidExtension && isValidSize;
    },
    downloadExampleFile() {
      const link = document.createElement('a');
      link.href = `./model-import-article.xlsx`;
      link.download = 'model-import-article.xlsx';
      link.click();
    },
    onDropFilesSelected(files) {
      this.dropFiles = [files[files.length - 1]];
    },
    async handleFileUpload() {
      if (!this.fileValidation(this.dropFiles[0])) {
        this.$buefy.toast.open({
          message:
            'Le fichier doit être au format Excel (XLS ou XLSX) et ne doit pas dépasser 8 Mo',
          type: 'is-danger',
        });
        return;
      }

      const formData = new FormData();
      if (this.dropFiles.length > 0) {
        formData.append('file', this.dropFiles[0]);
      }

      this.isUploading = true;
      this.uploadProgress = 0;

      try {
        const response = await axios.post('/imports/file', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 480000, // 8 minutes en millisecondes (8 * 60 * 1000)
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
          },
        });

        const responseData = response.data;

        if (responseData && responseData.serverError?.statusCode !== 404) {
          this.itemsCount = responseData.itemsCount || 0;
          this.importedCount = responseData.importedCount || 0;
          this.createdCount = responseData.createdCount || 0;
          this.updatedCount = responseData.updatedCount || 0;
          this.errorCount = responseData.errorCount || 0;
          this.errors = responseData.errors || [];
          this.countItemDuplicated = responseData.countItemDuplicated || 0;
          this.sfrReStoresId = responseData.sfrReStoresId || 0;

          this.isCollapseOpen = false; // Fermer le collapse
          this.isModalActive = true;
        } else {
          this.$buefy.toast.open({
            message: `Erreur serveur : ${responseData.serverError.message}`,
            type: 'is-danger',
          });
        }
      } catch (error) {
        this.$buefy.toast.open({
          message: 'Échec du téléchargement du fichier',
          type: 'is-danger',
        });
      } finally {
        this.isUploading = false;
        this.uploadProgress = 0;
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    showStoreModal() {
      this.isStoreModalActive = true;
    },
    closeStoreModal() {
      this.isStoreModalActive = false;
    },
    closeModal() {
      this.isModalActive = false;
      this.dropFiles = [];
    },
    showCategoryInfo(category) {
      if (this.selectedCategory === category) {
        this.selectedCategory = null;
      } else {
        this.selectedCategory = category;
      }
    },
    getCategoryInfo(category) {
      return this.categoryInfo[category] || 'Information non disponible';
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
  },
};
</script>

<style scoped>
.hero {
  background-color: #f5f5f5;
}

.box {
  max-width: 600px;
  width: 100%;
  padding: 1rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.logo {
  max-width: 200px;
}

.help-button {
  margin-left: auto;
  /* Pousse le bouton vers la droite */
}

.file,
.tags,
.section {
  margin-bottom: 1.5rem;
}

.button.is-primary {
  margin-top: 1rem;
}

.button-text {
  margin-left: 0.25rem;
}

/* Styles pour la modale */
.table {
  margin-top: 1rem;
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.small-button .button-text {
  margin-left: 0.25rem;
}

.progress-container {
  margin-top: 1rem;
}

.progress {
  width: 100%;
}

/* Styles pour l'onglet latéral */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  /* Fixée à droite de l'écran */
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-left: 1px solid #ddd;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  /* Initialement cachée */
  transition: transform 0.3s ease;
  overflow-y: auto;
  padding: 1rem;
  z-index: 1000;

  /* Styles pour les appareils mobiles */
  @media (min-width: 768px) {
    width: 500px;
  }
}

.sidebar.is-active {
  transform: translateX(0);
  /* Affiche la sidebar */
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  /* Assure que le contenu prend toute la hauteur de la sidebar */
}

.close-button {
  background: #007bff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  margin-bottom: 1rem;
  align-self: flex-start;
  /* Aligne le bouton de fermeture en haut à gauche */
}

.close-button i {
  font-size: 18px;
}

.text-content {
  margin-top: 0;
  /* Aligne le texte au top */
  margin-left: 0;
  /* Aligne le texte à gauche */
  text-align: left;
  /* Aligne le texte à gauche */
  flex: 1;
}

.title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

p {
  font-size: 14px;
  color: #6c757d;
}
ul {
  margin: 0 !important;
  padding: 0;
}
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal-card {
  padding: 30px;
}
.modal-card-body {
  padding: 20px 30px;
}
.modal-card-foot {
  justify-content: center;
}
.modal-card-body p {
  margin-bottom: 0.3rem;
}

.sidebar-footer {
  margin-top: 2rem;
}
.error-import {
  margin: 1rem;
  display: flex;
  gap: 0.5rem;
}
.collapse-trigger {
  display: flex;
  justify-content: center;
}
.is-error,
.is-error strong {
  color: red;
}
.error-list {
  padding: 0 0 0.5rem 0;
  border-bottom: 1px solid #ddd;
}
</style>
