<template>
  <section
    class="hero is-fullheight is-flex is-justify-content-center is-align-items-center"
  >
    <div class="box has-text-centered">
      <!-- Utilisation du logo -->
      <img class="logo" src="@/assets/logo_inov/logo.png" alt="Logo" />

      <form @submit.prevent="login">
        <div class="field">
          <label class="label" for="username">Username:</label>
          <div class="control">
            <input class="input" type="text" v-model="username" required />
          </div>
        </div>
        <div class="field">
          <label class="label" for="password">Password:</label>
          <div class="control">
            <input class="input" type="password" v-model="password" required />
          </div>
        </div>
        <button class="button is-primary is-fullwidth" type="submit">
          Login
        </button>
      </form>

      <p v-if="errorMessage" class="has-text-danger">{{ errorMessage }}</p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      users: [
        { id: 'fabien.leroy@srr.fr', password: 'Inovshop2024!' },
        { id: 'sebastien.gomer@srr.fr', password: 'Inovshop2024!' },
        { id: 'admin-digital@inovshop.com', password: 'Inovshop2024!' },
        { id: 'jean-marc.toinette@srr.fr', password: 'Inovshop2024!' },
      ],
    };
  },
  methods: {
    login() {
      const user = this.users.find(
        (u) => u.id === this.username && u.password === this.password,
      );

      if (user) {
        localStorage.setItem(
          'token',
          'XJ7IS9m9Kx6iBpOzhX27xA3yP3SsFfWW51mtxK9Yy4i63kalOCURpSiMu3EZw6YV',
        ); // Stockez le token JWT ici
        this.$router.push('/importForm'); // Redirection vers la page protégée
      } else {
        this.errorMessage = 'Invalid credentials';
      }
    },
  },
};
</script>

<style scoped>
.hero {
  background-color: #f5f5f5;
}

.box {
  max-width: 400px;
  width: 100%;
  text-align: center;
  /* Aligner le contenu au centre */
}

.logo {
  max-width: 200px;
  /* Ajuster la taille du logo au besoin */
  margin-bottom: 2rem;
}

.field {
  margin-bottom: 1.5rem;
}

.button.is-primary {
  margin-top: 1rem;
}
</style>
